<template>
  <div>
    <div class="option-header">
      <span class="text-600">Filter Tickets</span>
      <div class="btn float-right" v-on:click="toggleSideOptions">
        <i class="fas fa-chevron-right"></i><i class="fas fa-chevron-right"></i>
      </div>
    </div>
    <div class="p-3 py-3">
      <div class="option-label">
        <small class="float-left">Date From</small>
        <small class="float-right" v-on:click="() => {this.filters.dateRange = this.$defaultDates}"><i class="fas fa-redo"></i></small>
        &nbsp;
      </div>
      <div class="option-list">
        <div class="text-muted">
          <v-date-picker
            v-model="filters.dateRange"
            :placeholder="`${formatDate(filters.dateRange[0])} - ${formatDate(filters.dateRange[1])}`"
            :range="true"
            input-class="vue-datepicker"
          >
          </v-date-picker>
        </div>
      </div>
      <input
        type="checkbox"
        v-model="filters.includeDate"
      >
      <small for="includeDate" class="p-2 mb-2">Include date range on searches</small>
      {{date}}

      <!-- Segments -->
      <div class="option-label">
        <small class="float-left">Segments</small>
        <small class="float-right" v-on:click="toggleOption('showSegment')"><i class="fas fa-edit"></i></small>
        <small class="float-right" v-if="filters.segment.length > 0" v-on:click="clearOption('segment')"><i class="fas fa-redo"></i></small>
        &nbsp;
      </div>
      <div class="option-list">
        <div class="text-muted" v-if="filters.segment.length === 0">All segments</div>
        <span
          class="btn btn-primary"
          v-for="opt in filters.segment"
          v-bind:key="opt"
          v-on:click="removeItem('segment', opt)"
        >
          {{$segments.find(r => r.value === opt).text}}
        </span>
        <v-select
          v-if="options.showSegment"
          multiple
          v-model="filters.segment"
          :value="filters.segment"
          :options="$segments"
          :reduce="options => options.value" label="text"
          :selectable="option => !filters.segment.includes(option.value)"
          v-on:input="addSegment"
        ></v-select>
      </div>

      <!-- Clients -->
      <div class="option-label" v-if="this.user.role !== 'Customer'">
        <small class="float-left">Clients</small>
        <small class="float-right" v-on:click="toggleOption('showClient')"><i class="fas fa-edit"></i></small>
        <small class="float-right" v-if="filters.client.length > 0" v-on:click="clearOption('client')"><i class="fas fa-redo"></i></small>
        &nbsp;
      </div>
      <div class="option-list" v-if="this.user.role !== 'Customer'">
        <div class="text-muted" v-if="filters.client.length === 0">All clients</div>
        <span
          class="btn btn-primary"
          v-for="opt in filters.client"
          v-bind:key="opt"
          v-on:click="removeItem('client', opt)"
        >
          {{clients.find(r => r.value === opt).text}}
        </span>
        <v-select
          v-if="options.showClient"
          multiple
          v-model="filters.client"
          :value="filters.client"
          :options="clients.filter(r => filters.segment.length === 0 ? true : filters.segment.includes(r.segment))"
          :reduce="options => options.value" label="text"
          :selectable="option => !filters.client.includes(option.value)"
          v-on:input="addClient"
        ></v-select>
      </div>

      <!-- Business Unit -->
      <div class="option-label" v-if="this.user.role !== 'Customer'">
        <small class="float-left">Business Units</small>
        <small class="float-right" v-on:click="toggleOption('showBusinessUnit')"><i class="fas fa-edit"></i></small>
        <small class="float-right" v-if="filters.businessUnit.length > 0" v-on:click="clearOption('businessUnit')"><i class="fas fa-redo"></i></small>
        &nbsp;
      </div>
      <div class="option-list" v-if="this.user.role !== 'Customer'">
        <div class="text-muted" v-if="filters.businessUnit.length === 0">All business units</div>
        <span
          class="btn btn-primary"
          v-for="opt in filters.businessUnit"
          v-bind:key="opt"
          v-on:click="removeItem('businessUnit', opt)"
        >
          {{businessUnits.find(r => r.value === opt).text}}
        </span>
        <v-select
          v-if="options.showBusinessUnit"
          multiple
          v-model="filters.businessUnit"
          :value="filters.businessUnit"
          :options="businessUnits"
          :reduce="options => options.value" label="text"
          :selectable="option => !filters.businessUnit.includes(option.value)"
        ></v-select>
      </div>

      <!-- Agents -->
      <div class="option-label" v-if="this.user.role !== 'Customer'">
        <small class="float-left">Agents</small>
        <small class="float-right" v-on:click="toggleOption('showAgent')"><i class="fas fa-edit"></i></small>
        <small class="float-right" v-if="filters.agent.length > 0" v-on:click="clearOption('agent')"><i class="fas fa-redo"></i></small>
        &nbsp;
      </div>
      <div class="option-list" v-if="this.user.role !== 'Customer'">
        <div class="text-muted" v-if="filters.agent.length === 0">All agents</div>
        <span
          class="btn btn-primary"
          v-for="opt in filters.agent"
          v-bind:key="opt"
          v-on:click="removeItem('agent', opt)"
        >
          {{agents.find(r => r.value === opt).text}}
        </span>
        <v-select
          v-if="options.showAgent"
          multiple
          v-model="filters.agent"
          :value="filters.agent"
          :options="agents"
          :reduce="options => options.value" label="text"
          :selectable="option => !filters.agent.includes(option.value)"
        ></v-select>
      </div>

      <!-- Labels -->
      <div class="option-label">
        <small class="float-left">Labels</small>
        <small class="float-right" v-on:click="toggleOption('showLabel')"><i class="fas fa-edit"></i></small>
        <small class="float-right" v-if="filters.label.length > 0" v-on:click="clearOption('label')"><i class="fas fa-redo"></i></small>
        &nbsp;
      </div>
      <div class="option-list">
        <div class="text-muted" v-if="filters.label.length === 0">All labels</div>
        <span
          class="btn btn-primary"
          v-for="opt in filters.label"
          v-bind:key="opt"
          v-on:click="removeItem('label', opt)"
        >
          {{labels.find(r => r.value === opt).text}}
        </span>
        <v-select
          v-if="options.showLabel"
          multiple
          v-model="filters.label"
          :value="filters.label"
          :options="labels"
          :reduce="options => options.value" label="text"
          :selectable="option => !filters.label.includes(option.value)"
        ></v-select>
      </div>

      <!-- Classification -->
      <div class="option-label">
        <small class="float-left">Classification</small>
        <small class="float-right" v-on:click="toggleOption('showIssueClassification')"><i class="fas fa-edit"></i></small>
        <small class="float-right" v-if="filters.issueClassification.length > 0" v-on:click="clearOption('issueClassification')"><i class="fas fa-redo"></i></small>
        &nbsp;
      </div>
      <div class="option-list">
        <div class="text-muted" v-if="filters.issueClassification.length === 0">All classifications</div>
        <span
          class="btn btn-primary"
          v-for="opt in filters.issueClassification"
          v-bind:key="opt"
          v-on:click="removeItem('issueClassification', opt)"
        >
          {{opt}}
        </span>
        <v-select
          v-if="options.showIssueClassification"
          multiple
          v-model="filters.issueClassification"
          :value="filters.issueClassification"
          :options="$issueClassifications"
          :reduce="options => options.value" label="text"
          :selectable="option => !filters.issueClassification.includes(option.value)"
        ></v-select>
      </div>

      <!-- isActive -->
      <div class="option-label">
        <small class="float-left">Ticket state</small>
        <small class="float-right" v-on:click="toggleOption('showState')"><i class="fas fa-edit"></i></small>
        <small class="float-right" v-if="filters.isActive.length > 0" v-on:click="clearOption('isActive')"><i class="fas fa-redo"></i></small>
        &nbsp;
      </div>
      <div class="option-list">
        <div class="text-muted" v-if="filters.isActive.length === 0">All tickets</div>
        <span
          class="btn btn-primary"
          v-for="opt in filters.isActive"
          v-bind:key="opt"
          v-on:click="removeItem('isActive', opt)"
        >
          {{$state.find(r => r.value === opt).text}}
        </span>
        <v-select
          v-if="options.showState"
          multiple
          v-model="filters.isActive"
          :value="filters.isActive"
          :options="$state"
          :reduce="options => options.value" label="text"
          :selectable="option => !filters.isActive.includes(option.value)"
        ></v-select>
      </div>

      <!-- status -->
      <div class="option-label">
        <small class="float-left">Status</small>
        <small class="float-right" v-on:click="toggleOption('showStatus')"><i class="fas fa-edit"></i></small>
        <small class="float-right" v-if="filters.status.length > 0" v-on:click="clearOption('status')"><i class="fas fa-redo"></i></small>
        &nbsp;
      </div>
      <div class="option-list">
        <div class="text-muted" v-if="filters.status.length === 0">All status</div>
        <span
          v-for="opt in filters.status"
          v-bind:key="opt"
          v-bind:class="['btn bg-' + opt]"
          v-on:click="removeItem('status', opt)"
        >
          {{$statuses.find(r => r.value === opt).text}}
        </span>
        <v-select
          v-if="options.showStatus"
          multiple
          v-model="filters.status"
          :value="filters.status"
          :options="$statuses"
          :reduce="options => options.value" label="text"
          :selectable="option => !filters.status.includes(option.value)"
        ></v-select>
      </div>

      <!-- Priority -->
      <div class="option-label">
        <small class="float-left">Severity</small>
        <small class="float-right" v-on:click="toggleOption('showPriority')"><i class="fas fa-edit"></i></small>
        <small class="float-right" v-if="filters.priority.length > 0" v-on:click="clearOption('priority')"><i class="fas fa-redo"></i></small>
        &nbsp;
      </div>
      <div class="option-list">
        <div class="text-muted" v-if="filters.priority.length === 0">All severities</div>
        <span
          class="btn btn-primary"
          v-for="opt in filters.priority"
          v-bind:key="opt"
          v-bind:class="['btn bg-priority-' + opt]"
          v-on:click="removeItem('priority', opt)"
        >
          {{$priorities.find(r => r.value === opt).text}}
        </span>
        <v-select
          v-if="options.showPriority"
          multiple
          v-model="filters.priority"
          :value="filters.priority"
          :options="$priorities"
          :reduce="options => options.value" label="text"
          :selectable="option => !filters.priority.includes(option.value)"
        ></v-select>
      </div>

      <!-- Agent Priority -->
      <div class="option-label" v-if="this.user.role !== 'Customer'">
        <small class="float-left">Agent Severity</small>
        <small class="float-right" v-on:click="toggleOption('showAgentPriority')"><i class="fas fa-edit"></i></small>
        <small class="float-right" v-if="filters.agentPriority.length > 0" v-on:click="clearOption('agentPriority')"><i class="fas fa-redo"></i></small>
        &nbsp;
      </div>
      <div class="option-list" v-if="this.user.role !== 'Customer'">
        <div class="text-muted" v-if="filters.agentPriority.length === 0">All severities</div>
        <span
          class="btn btn-primary"
          v-for="opt in filters.agentPriority"
          v-bind:key="opt"
          v-on:click="removeItem('agentPriority', opt)"
        >
          {{$agentPriorities.find(r => r.value === opt).text}}
        </span>
        <v-select
          v-if="options.showAgentPriority"
          multiple
          v-model="filters.agentPriority"
          :value="filters.agentPriority"
          :options="$agentPriorities"
          :reduce="options => options.value" label="text"
          :selectable="option => !filters.agentPriority.includes(option.value)"
        ></v-select>
      </div>


      <br>
      <div class="btn btn-primary form-control" v-on:click="setTicketFilters(false)">Apply filters</div>
      <div class="btn btn-secondary mt-2 form-control" v-on:click="setTicketFilters(true)">Clear filters</div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    data() {
      return {
        user: this.$userData.user,
        clients: [],
        date: '',
        labels: [],
        filters: {
          date: '',
          dateRange: this.$defaultDates,
          businessUnit: [],
          client: [],
          issueClassification: [],
          agent: [],
          status: [],
          segment: [],
          label: [],
          priority: [],
          agentPriority: [],
          includeDate: false,
          isActive: []
        },
        default_filters: {
          date: '',
          dateRange: this.$defaultDates,
          segment: [],
          businessUnit: [],
          client: [],
          issueClassification: [],
          agent: [],
          status: [],
          label: [],
          priority: [],
          agentPriority: [],
          includeDate: false,
          isActive: []
        },
        options: {
          showClient: false,
          showIssueClassification: false,
          showBusinessUnit: false,
          showAgent: false,
          showState: false,
          showStatus: false,
          showSegment: false,
          showLabel: false,
          showPriority: false,
          showAgentPriority: false
        }
      }
    },
    methods: {
      loader: function(val){
        this.$emit('loader', val)
      },
      toggleSideOptions: function(){
        this.$emit('toggleSideOptions', 'showTicketsOptions')
      },
      formatDate: function (date){
        return moment((new Date(date).addHours(8)).toISOString(), 'YYYY/MM/DD HH:mm:ss').format("MMM DD, YYYY")
      },
      setTicketFilters: function(reset){
        let params, validKeys = ['dateRange', 'includeDate']
        this.filters = reset ? this.default_filters : this.filters
        params = JSON.parse(JSON.stringify(this.filters))
        localStorage.setObject('ticketFilters', params)
        Object.keys(params).forEach((key) => (validKeys.includes(key)  || params[key].length > 0 ) || delete params[key])
        if(Object.keys(params).length === 0  || reset)
          localStorage.removeItem('ticketFilters')
        this.$emit('setTicketFilters', params)
      },
      addSegment: function(){
        this.filters.client = this.clients.filter(r => this.filters.client.includes(r.value) && (this.filters.segment.length === 0 || this.filters.segment.includes(r.segment))).map(r => r.value)
      },
      addClient: function(){
        this.getTags()
      },
      removeItem: function(option, val){
        this.filters[option] = this.filters[option].filter(function (r) { return r !== val })
        if(option == "segment")
          this.filters.client = this.clients.filter(r => this.filters.client.includes(r.value) && (this.filters.segment.length === 0 || this.filters.segment.includes(r.segment))).map(r => r.value)
        if(option == "client")
          this.getTags()
      },
      clearOption: function(option){
        this.filters[option] = []
      },
      toggleOption: function(option){
        this.options[option] = !this.options[option]
      },
      async getUsers(){
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/users?role=Manager,Agent`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.agents = response.data.users.filter(r => r.roles === "Agent" || (r.roles === "Manager" && r.isAgent)).map((r) => {
            return { key: r._id, text: r.name, value: r._id, email: r.email }
          })
        } catch (error) {
          console.log(error)
        }
      },
      async getClients(){
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/clients`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.clients = response.data.clients.map((r) => {
            return { key: r._id, text: r.name, value: r._id, code: r.code, segment: r.segment }
          })
          this.getTags()
          this.getQuery()
        } catch (error) {
          console.log(error)
        }
      },
      async getTags(){
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/tags?type=label`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.labels = response.data.tags.filter(r => {
            if(r.clients.length == 0)
              return true
            else if(this.filters.client.length == 0)
              return this.clients.map(c => c.key).some(s => r.clients.map(c => c._id).includes(s))
            else
              return this.filters.client.some(s => r.clients.map(c => c._id).includes(s))
          }).map(r => {
            return { key: r._id, text: r.name, value: r.code, clients: r.clients }
          })
          this.filters.label = this.filters.label.filter(r => this.labels.map(q => q.key).includes(r.key))
        } catch (error) {
          console.log(error)
        }
      },
      async getBusinessUnits(){
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/bunits`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.businessUnits = response.data.bunits.filter(r => r.isActive).filter(r => {
            return this.user.role == "Admin" || this.user.bunit.map(q => q._id).includes(r._id)
          }).map(r => {
            return { key: r._id, text: r.name, value: r._id }
          })
        } catch (error) {
          console.log(error)
        }
      },
      getQuery: function(){
        let isValid = false
        if(this.$route.query.dateRange){
          this.filters.dateRange = this.$route.query.dateRange.split(',')
          isValid = true
        }
        if(this.$route.query.client){
          this.filters.client = this.$route.query.client.split(',').map(r => {
            return this.clients.find(q => this.$insensitiveComparison(q.value, r) || this.$insensitiveComparison(q.code, r)).value
          }).filter(r => r)
          isValid = this.filters.client.length > 0
        }
        if(this.$route.query.issueClassification){
          this.filters.issueClassification = this.$route.query.issueClassification.split(',').map(r => {
            return this.$issueClassifications.find(q => this.$insensitiveComparison(q.value, r) || this.$insensitiveComparison(q.key, r)).value
          }).filter(r => r)
          isValid = this.filters.issueClassification.length > 0
        }
        if(this.$route.query.status){
          this.filters.status = this.$route.query.status.split(',').map(r => {
            return this.$statuses.find(q => this.$insensitiveComparison(q.value, r)).value
          }).filter(r => r)
          isValid = this.filters.status.length > 0
        }
        if(this.$route.query.segment){
          this.filters.segment = this.$route.query.segment.split(',').map(r => {
            return this.$segments.find(q => this.$insensitiveComparison(q.value, r)).value
          }).filter(r => r)
          isValid = this.filters.segment.length > 0
        }
        if(this.$route.query.label){
          this.filters.label = this.$route.query.label.split(',').map(r => {
            return this.labels.find(q => this.$insensitiveComparison(q.value, r)).value
          }).filter(r => r)
          isValid = this.filters.label.length > 0
        }
        if(this.$route.query.priority){
          this.filters.priority = this.$route.query.priority.split(',').map(r => {
            return this.$priorities.find(q => this.$insensitiveComparison(q.value, r) || this.$insensitiveComparison(q.code, r)).value
          }).filter(r => r)
          isValid = this.filters.priority.length > 0
        }
        if(this.$route.query.agentPriority){
          this.filters.agentPriority = this.$route.query.agentPriority.split(',').map(r => {
            return this.$priorities.find(q => this.$insensitiveComparison(q.value, r) || this.$insensitiveComparison(q.code, r)).value
          }).filter(r => r)
          isValid = this.filters.agentPriority.length > 0
        }
        if(this.$route.query.state){
          this.filters.isActive = this.$route.query.state.split(',').map(r => {
            return this.$insensitiveComparison('active', r)
          })
          isValid = this.filters.isActive.length > 0
        }
        if(this.$route.query.isActive){
          this.filters.isActive = this.$route.query.isActive.split(',').map(r => {
            return this.$insensitiveComparison('true', r) || r === '1' || r === 1
          })
          isValid = this.filters.isActive.length > 0
        }
        if(isValid)
          this.setTicketFilters(false)
      }
    },
    mounted: function() {
      this.getBusinessUnits()
      this.getUsers()
      this.getClients()
      if(localStorage.getObject('ticketFilters')){
        this.filters = {
          ...this.default_filters,
          ...localStorage.getObject('ticketFilters')
        }
        this.loader(true)
        setTimeout(() => this.setTicketFilters(false), 1500)
        this.loader(false)
      }
    }
  }
  Date.prototype.addHours= function(h){
    this.setHours(this.getHours()+h);
    return this;
  }
</script>
